import React from 'react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { EnvConfig } from '../config/EnvConfig'
import turnstileScript from '../templates/externalScripts/turnstileScript'
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const TURNSTILE = 'TURNSTILE'
const TURNSTILE_ID = 'turnistileCaptcha'
const DEFAULT_ACTION = 'REGISTRATION'

export const useTurnstileOn = () => {
  const captchaTurnstileOn =
    useFeatureValue('captcha_registration_solution') === TURNSTILE

  return {
    captchaTurnstileOn,
  }
}

export const Turnstile = ({ 
  action = DEFAULT_ACTION,
  onCaptchaVerified = () => null, 
  onCaptchaExpired = () => null, 
}) => {
  const captchaIsAlreadyInitialized = useRef(false)
  const { captchaTurnstileOn } = useTurnstileOn()

  const checkIfCaptchaIsAlreadyRendered = () => {
    const turnstileWrapper = document.getElementById(TURNSTILE_ID)
    const alreadyHasElements = turnstileWrapper?.children?.length >= 1

    return captchaIsAlreadyInitialized.current || alreadyHasElements
  }

  const initTurnstile = () => {
    window.turnstile.render(`#${TURNSTILE_ID}`, {
      action,
      sitekey: EnvConfig.GATSBY_CLOUDFARE_CAPTCHA_SITEKEY,
      theme: 'light',
      callback: onCaptchaVerified,
      ['expired-callback']: onCaptchaExpired,
    })
  }

  const renderCaptcha = () => {
    const script = turnstileScript(initTurnstile)
    document.head.appendChild(script)
    captchaIsAlreadyInitialized.currrent = true
    return script
  }

  const destroyCaptchaScript = (script) => {
    if (script && document.head.contains(script)) {
      document.head.removeChild(script)
      captchaIsAlreadyInitialized.current = false
    }
  }

  useEffect(() => {
    if (!captchaTurnstileOn) return    

    if (checkIfCaptchaIsAlreadyRendered()) return

    const script = renderCaptcha()

    return () => {
      destroyCaptchaScript(script)
    }
  }, [captchaTurnstileOn, action, onCaptchaVerified, onCaptchaExpired])

  if (!captchaTurnstileOn) return null

  return (
    <div className="checkbox" style={{ padding: '1rem 0' }}>  
      <div id={TURNSTILE_ID} />
    </div>
  )
}

Turnstile.propTypes = {
  action: PropTypes.string,
  onCaptchaVerified: PropTypes.func,
  onCaptchaExpired: PropTypes.func,
}