import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../atoms/modal'
import { Button, If } from '@techmobilt/ui-components'
import { useTranslation } from '../../../context/translationProvider'
import {
  StyledFirstStepContainer,
  StyledFirstStepContent,
  StyledModalContentWrapper,
  StyledVerificationMessage,
  StyledVerificationStatusTitle 
} from './styles'
import { Image } from '../../atoms/image'
import selfieAndIdIcon from '../../../images/icon_selfie_and_id_gray.svg'
import Checkmark from '../../../images/icon_circle_check_green_dark.svg'
import SupportChatIcon from '../../../images/icon_support_chat.svg'
import icon_selfie_and_id_failed from '../../../images/icon_selfie_and_id_red.svg'
import { toast } from '../../atoms'
import { themeStyles } from '../../../styles/theme'
import { AuthContext } from '../../../context/authProvider'
import { isMobile } from '../../../utils/generic'
import { CafIFrame } from '../../molecules/cafIFrame'
import {
  CAF_MESSAGE_CODES,
  FIVE_SECONDS,
  KycStatus 
} from '../../../utils/constants'
import { getKYCStatusData } from '../../../adapters/kyc'
import storageService from '../../../services/storageService'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { StyledCheckedIcon } from '../registerContent/styles'

export const KYCVerifyAccountModal = ({ 
  isOpen,
  onClose = () => null,
  onFinish = () => null,
}) => {
  const { open: openDepositDrawer } = usePaymentDrawerContext()
  const { logoutUser, safeSetUser } = useContext(AuthContext)
  const { translate, lang } = useTranslation()
  const [step, setStep] = useState(0)

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  useEffect(() => {
    if (!isOpen || !lang) return
    const registrationCompletedToast 
      = storageService.getValue('registrationCompletedToast')

    const showToast = registrationCompletedToast === 'show'

    if (showToast) {
      toast.success(translate('register.lead.success'))
      storageService.removeValue('registrationCompletedToast')
    }
  }, [isOpen, lang])

  const closeCafIFrame = (status) => {
    if (status !== CAF_MESSAGE_CODES.ONBOARDING_FINISHED) return

    const poolingInterval = setInterval(async () => {
      const user = storageService.getUser()
      const response = await getKYCStatusData(user.id)
      const status = response?.data?.status || null
      if (status === KycStatus.APPROVED) {
        clearInterval(poolingInterval)
        await safeSetUser()
        setStep(2)
        return
      }

      const KYC_REJECTED = 
        status === KycStatus.REJECTED || status === KycStatus.REPROVED
      
      if (KYC_REJECTED) {
        clearInterval(poolingInterval)
        setStep(3)
        return
      }
    }, FIVE_SECONDS)
  }

  const handleDeposit = () => {
    onClose()
    openDepositDrawer()
    onFinish()
  }

  const handleLogout = () => {
    logoutUser()
    onClose()
  }

  return (
    <Modal
      theme="form"
      isOpen={isOpen}
      onClose={onClose}
      title={translate('account.verification.verification')}
      hideClose
    >
      <StyledModalContentWrapper>

          <If 
            condition={step === 3}
            render={() => (
              <StyledFirstStepContainer>
                <StyledVerificationStatusTitle>
                  {translate('registration.selfieAndId.failTitle')}
                </StyledVerificationStatusTitle>

                <StyledCheckedIcon url={icon_selfie_and_id_failed} isFail />

                <StyledVerificationMessage>
                {translate('registration.selfieAndId.failDescription')}
                </StyledVerificationMessage>
        
                <Button $block onClick={() => setStep(1)}>
                  {translate('common.tryAgain')}
                </Button>

                <Button
                  $layout="link"
                  $colorSchema="black"
                  $weight={400}
                  style={{ textDecoration: 'underline', padding: 0 }}
                  onClick={handleLogout}
                >
                  {translate('navigation.logout')}
                </Button>
              </StyledFirstStepContainer>
            )} 
          />

        <If 
          condition={step === 0} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
              <StyledVerificationStatusTitle>
                {translate('account.verfication.title')}
              </StyledVerificationStatusTitle>

              <Image url={selfieAndIdIcon} />

              <StyledVerificationMessage>
                {translate('account.verfication.description')}
              </StyledVerificationMessage>
              </StyledFirstStepContent>
              <StyledFirstStepContent>
              <Button
                $colorSchema="light"
                $weight={400}
                style={{ 
                  backgroundColor: themeStyles.colors.lightGreys[1].main 
                }}
                $roundeed
                $size="sm"
                onClick={toggleContactUs}
                $leftIcon={<Image url={SupportChatIcon} />}
              >
                {translate('account.verfication.contactSupport')}
              </Button>

              <Button $block onClick={() => setStep(1)}>
                {translate('account.verfication.activateYourAccount')}
              </Button>
              <Button
                $layout="link"
                $colorSchema="black"
                $weight={400}
                style={{ textDecoration: 'underline', padding: 0 }}
                onClick={handleLogout}
              >
                {translate('navigation.logout')}
              </Button>
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />

        <If
          condition={step === 1}
          render={() => (
            <CafIFrame
              minHeight={isMobile() ? '90vh' : '75vh'}
              chosenDocument={'IDENTIFICATION_AND_SELFIE'}
              closeIFrame={closeCafIFrame}
              requestParams={{ requestType: 'REGISTRATION_REQUEST' }}
            />
          )}
        />

        <If 
          condition={step === 2} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
              <Image url={Checkmark} />

              <StyledVerificationStatusTitle>
                {translate('register.verificationSuccess')}
              </StyledVerificationStatusTitle>
              <StyledVerificationMessage>
                {translate('register.verificationSuccessDescription')}
              </StyledVerificationMessage>
            </StyledFirstStepContent>

            <StyledFirstStepContent style={{ gap: 15 }}>
              <Button $block onClick={handleDeposit}>
                {translate('navigation.deposit')}
              </Button>

              <Button
                $block
                onClick={onClose}
                $layout="outline"
                $colorSchema="black"
              >
                {translate('common.notNow')}
              </Button>
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />
      </StyledModalContentWrapper>

    </Modal>
  )
}

KYCVerifyAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
}
