import React from 'react'
import { FormModal } from '../formModal'
import { MyAccountBankAccountCard } from '../MyAccountBankAccountCard'
import { AddBankAccountHeader } from '../AddBankAccountHeader'
import BasicAlert from '../basicAlert'
import InformationIcon from '../../../images/icon_information.svg'
import WarningIcon from '../../../images/warning.svg'
import { If } from '../../atoms/if'
import { FormStyleWrapper, StyledCardWrapper, StyledDisplaySelectedBank, StyledFormHeader, StyledSelectBankHeader, StyledWrapperSelectedBank } from './styles'
import { Image } from '../../atoms/image'
import { getBankIcon } from '../../../utils/bankIcons'
import { Text } from '../../atoms/text'

export const BankDetailsForm = ({
  formFields,
  setBankDetails,
  bankDetails,
  translate,
  waitingLivenessAccount,
  user,
  selectedBank,
  handlePreviousStep,
}) => {
  const isWaitingLivenessProcess = !!waitingLivenessAccount

  const pageTitle = 'addBankAccount.formTitleLiveness'
  const pageSubtitle = 'addBankAccount.formSubtitleLiveness'

  return (
    <>
      {isWaitingLivenessProcess ? (
        <>
          <AddBankAccountHeader 
            pageTitle={translate(pageTitle).replace(
              '{NAME}',
              user.first_name
            )}
            pageSubtitle={translate(pageSubtitle)}
          />

          <StyledCardWrapper>
            <MyAccountBankAccountCard
              bankAccount={waitingLivenessAccount}
              tagLabel={translate('payments.bankAccountKeys.tags.accounts')}
              onDelete={() => {}}
              onUpdateDefault={() => {}}
              showOnly
            />
          </StyledCardWrapper>
        </>
      ) : (
        <>
          <StyledWrapperSelectedBank>
            <StyledSelectBankHeader>
              <Text>
                {translate('addBankAccount.bankSelected')}
              </Text>

              <button onClick={handlePreviousStep}>
                {translate('addBankAccount.changeBank')}
              </button>
            </StyledSelectBankHeader>

            <StyledDisplaySelectedBank>
              <Image 
                url={getBankIcon(selectedBank?.name?.toUpperCase(), true)}
                alt={selectedBank.name} width={40} 
              />

              <Text>
                {selectedBank?.name}
              </Text>
            </StyledDisplaySelectedBank>
          </StyledWrapperSelectedBank>

          <StyledFormHeader>
            {translate('addBankAccount.addBankHeader')}
          </StyledFormHeader>
          <FormModal
            isOnlyForm
            formFields={formFields}
            formStyleWrapper={FormStyleWrapper}
            customSetData={setBankDetails}
            customData={bankDetails}
          />
          <If
            condition={bankDetails?.accountType === 'SAL'}
            render={() => (
              <BasicAlert
                mobileIcon={WarningIcon}
                desktopIcon={InformationIcon}
                description={translate('addBankAccount.salaryAccountWarning')}
              />
            )}
          />
        </>
      )}
    </>
  )
}
